import { Link, withPrefix } from "gatsby"
import React from "react"

const Hero = () => {
  return (
    <header
      id="blogheader"
      className="blog-header"
      style={{ backgroundImage: `url(${withPrefix("images/blog/hero.jpg")}` }}
    >
      <div className="row">
        <div className="small-12 columns">
          <div className="yellow-box wow fadeInUp" data-wow-duration="1.5s" style={{visibility: 'hidden'}}>
            <Link to="/blog">
              <h1>
                Blog skupiony na Tobie
              </h1>
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Hero
