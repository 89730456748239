import React from "react"
import Logo from "./logo"
import { graphql, Link, useStaticQuery } from "gatsby"

const Header = ()  => {

  const { strapiMenu } = useStaticQuery(graphql`
      query {
          strapiMenu {
              items {
                  title,
                  link
              }
          }
      }
  `)

  const { items } = strapiMenu

  const menuItemsJsx = items.map((item: any, index: number) => (
    <li
      key={index}
      className="menu-item">
      <Link to={item.link} aria-current="page">{item.title}</Link>
    </li>
  ))

  return (
    <nav id="mainnav">
      <div className="row expanded collapse" data-equalizer="mainnav" data-equalize-on="large">
        <div className="large-8 large-push-4 medium-12 small-12 columns text-right">
          <div id="mobilenav" className="display-table" data-equalizer-watch="mainnav">
            <div className="display-table-cell">
              <ul id="mainul" className="" style={{ display: 'block' }}>
                {menuItemsJsx}
              </ul>
              <ul id="adresul">
                <li>
                  <i className="yellow fa fa-map-marker" aria-hidden="true"></i>
                  Świdnica, ul. Waleriana Łukasińskiego 24/02
                </li>
                <li>
                  <i className="yellow fa fa-comments-o" aria-hidden="true"></i>
                  Zadzwoń: +48 603 631 009
                </li>
              </ul>

              <button className="hamburger hamburger--collapse" type="button">
					  <span className="hamburger-box">
					    <span className="hamburger-inner"></span>
					  </span>
              </button>
            </div>
          </div>
        </div>
        <div className="large-4 large-pull-8 medium-12 small-12 columns text-center large-text-left">
          <div className="display-table" data-equalizer-watch="mainnav">
            <div className="display-table-cell">
              <Link className="logo" to="/"
                    title="Centrum Medyczne Dent&amp;Beauty | Świdnica - Dent &amp; Beauty. Lekarze skupienii na pięknie. Świdnica">
                <Logo />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
