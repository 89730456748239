import { graphql, Link, useStaticQuery, withPrefix } from "gatsby"
import React from "react"

const Categories = () => {
  const query = useStaticQuery(graphql`
      query {
          allStrapiCategories {
              nodes {
                  title
                  slug
              }
          }
      }
  `)

  const { allStrapiCategories } = query

  const categoriesJsx = allStrapiCategories.nodes.map((category: any, index: number) => (
    <li className="menu-item menu-item-type-taxonomy menu-item-object-category" key={index}>
      <Link to={`/kategoria/${category.slug}`}>
        {category.title}
      </Link>
    </li>
  ))

  return (
    <div className="blog-categories wow fadeInUp" data-wow-duration="1s" style={{visibility: 'hidden'}}>
      <div className="row">
        <div className="small-12 columns">
			<span>
				Kategorie:
			</span>
          <ul id="menu-blog-menu" className="menu">
            {categoriesJsx}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Categories


