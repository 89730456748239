import React from "react"

import {Link} from "gatsby";

const PostCard = ({index, post, category}) => {
  return (
    <div className="large-4 medium-6 small-12 columns blog-posts-list-item wow fadeInUp"
         data-wow-duration="1.5s"
         data-wow-delay={`${index * 100}ms`}
         style={{visibility: 'hidden'}}
    >
      <div className="img">
        {post.fromOffer &&
        <div className="post-from-offer">
            Z naszej oferty
        </div>
        }
        <Link to={`/${category.slug}/${post.slug}`}>
          <img
            src={post.image.childImageSharp.fluid.src}
            alt={post.imageCaption}
          />
        </Link>
      </div>

      <div className="title">
        <Link to={`/${category.slug}/${post.slug}`}>
          {post.title}
        </Link>
      </div>
      <div className="excerpt">
        {post.excerpt}
      </div>
    </div>
  )
}

export default PostCard
